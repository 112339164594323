import type { FC } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useScript } from "@/hooks/script.hook";
import type { TradingServer } from "@/services/openapi";

// https://www.mql5.com/en/trading/widget

type Props = {
  servers: TradingServer[];
};

const containerId = "webterminal";

const MT4TerminalContainer: FC<Props> = ({ servers }) => {
  const { i18n } = useTranslation();
  const status = useScript("https://metatraderweb.app/trade/widget.js", { removeOnUnmount: true });

  useEffect(() => {
    if (status === "ready") {
      window!.MetaTraderWebTerminal("webterminal", {
        startMode: "login",
        language: i18n.resolvedLanguage,
        colorScheme: "black_on_white",
        utmSource: "www.doto.com",
        version: 4,
        server: servers[0]!.marketName!,
        servers: servers.map(({ marketName }) => marketName!),
      });
    }
  }, [status]);

  return <div className="size-full" id={containerId} />;
};

export { MT4TerminalContainer };
