import * as ProgressPrimitive from "@radix-ui/react-progress";
import { Slot, Slottable } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactNode } from "react";

import { cn } from "@/shared/styles";

import { Text, UnstyledButton } from "..";

const Strong = forwardRef<ElementRef<typeof Text>, ComponentPropsWithoutRef<typeof Text>>((props, ref) => {
  return <Text as="strong" inline color="primary" variant="S / Medium" {...props} ref={ref} />;
});

type ActionProps = Omit<ComponentPropsWithoutRef<typeof UnstyledButton>, "className"> & {
  icon?: ReactNode;
  asChild?: boolean;
};

const Action = forwardRef<ElementRef<typeof UnstyledButton>, ActionProps>(
  ({ children, icon, asChild, ...props }, ref) => {
    const Comp = asChild ? Slot : UnstyledButton;

    return (
      <Comp
        className="typography-S-Medium -mb-2 flex h-10 items-center gap-1 text-contrast-primary outline-none"
        {...props}
        ref={ref}
      >
        <Slottable>{children}</Slottable>
        {icon && <div className="*:size-4">{icon}</div>}
      </Comp>
    );
  },
);
Action.displayName = "InfoBlockAction";

const infoBlockStyles = cva("flex gap-3 rounded-lg p-4", {
  variants: {
    variant: {
      default: "bg-card-bg text-contrast-secondary",
      accent: "bg-card-bg-accent text-contrast-secondary",
      positive: "bg-positive-bg/10 text-positive-text",
      warning: "bg-warning-bg/10 text-warning-text",
      negative: "bg-negative-bg/10 text-negative-text",
    },
  },
});

type Props = ComponentPropsWithoutRef<"div"> &
  VariantProps<typeof infoBlockStyles> & {
    icon: ReactNode;
    text: ReactNode;
  };

const InfoBlock = forwardRef<ElementRef<typeof ProgressPrimitive.Root>, Props>(
  ({ className, icon, children, text, variant = "default", ...props }, ref) => (
    <div className={cn(infoBlockStyles({ variant }), className)} {...props} ref={ref}>
      <div className="*:size-6">{icon}</div>
      <div>
        <Text className="py-0.5" color="primary" variant="S / Regular">
          {text}
        </Text>
        {children}
      </div>
    </div>
  ),
);
InfoBlock.displayName = "InfoBlock";

const Component = Object.assign(InfoBlock, { Action, Strong });

export { Component as InfoBlock };
