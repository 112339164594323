import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { IconHistory } from "@/domains/icons";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { EmptyState } from "@/shared/ui";

const TransactionsEmptyState: FC = () => {
  const { t } = useTranslation();

  return (
    <EmptyState
      icon={<IconHistory />}
      title={t("transactions.no-items.title")}
      description={t("transactions.no-items.description")}
      action={
        <EmptyState.Button asChild>
          <Link
            to={cabinetRoutes.deposit}
            onClick={() => track(amplitudeEvents.transcationHistory.depositClickWithNoTransactions)}
          >
            {t("button.deposit-now")}
          </Link>
        </EmptyState.Button>
      }
    />
  );
};

export { TransactionsEmptyState };
