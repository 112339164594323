import { type FC } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, Navigate, useParams } from "react-router-dom";

import { CabinetHeader } from "@/layouts/cabinet/header";
import { CabinetPage } from "@/layouts/cabinet/page";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { TradingAccountStatus } from "@/services/openapi";
import { useAccountQuery } from "@/state/server/accounts";
import { useBonusesQuery } from "@/state/server/bonuses";

import { AccountContainer } from "./container";

const AccountPage: FC = () => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const { data, error } = useAccountQuery(id!);
  const { data: bonuses } = useBonusesQuery();

  if (error || (data && data.status !== TradingAccountStatus.Active)) {
    return <Navigate to={cabinetRoutes.dashboard} replace />;
  }

  if (!data || !bonuses) {
    return <CabinetPage.Loader />;
  }

  return (
    <CabinetPage
      size="sm"
      header={
        <CabinetHeader
          backButton={
            <CabinetHeader.BackLink
              to={{
                pathname: cabinetRoutes.dashboard,
                search: createSearchParams({ tab: data.type! }).toString(),
              }}
            />
          }
        >
          <CabinetHeader.Title>{t("accounts.details.title")}</CabinetHeader.Title>
        </CabinetHeader>
      }
    >
      <AccountContainer account={data} bonuses={bonuses.items!} />
    </CabinetPage>
  );
};

export { AccountPage };
