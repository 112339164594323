import { Intercom } from "@intercom/messenger-js-sdk";
import { type FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { useAuth } from "@/contexts/auth.context";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { onboardingRoutes } from "@/routes/onboarding.routes";
import { terminalRoutes } from "@/routes/terminal.routes";
import { useKycInfoQuery } from "@/state/server/kyc";
import { usePlatformData } from "@/state/server/platform/platform.hooks";
import { useProfileHeaderQuery } from "@/state/server/profile";
import { theme } from "@/styles";

import { closeLiveChat } from ".";

const IntercomWidget: FC = () => {
  const { i18n } = useTranslation();
  const { isAuthenticated } = useAuth();
  const { liveChatInfo } = usePlatformData();
  const { data: profile } = useProfileHeaderQuery({ enabled: isAuthenticated });
  const { data: kycData } = useKycInfoQuery({ enabled: isAuthenticated });
  const location = useLocation();

  const locale = useMemo(() => {
    if (liveChatInfo && liveChatInfo.groupsIds) {
      const currentLiveChatInfo = liveChatInfo.groupsIds.find(({ locale }) => locale === i18n.resolvedLanguage);

      if (currentLiveChatInfo) {
        return currentLiveChatInfo.locale!;
      }
    }
  }, [i18n.resolvedLanguage, liveChatInfo]);

  Intercom({
    app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    email: profile?.email || void 0,
    user_id: profile?.id,
    name: kycData?.profile?.firstName || void 0,
    phone: profile?.phoneNumber || void 0,
    language_override: locale,
    action_color: theme.colors.positiveText.value,
    background_color: theme.colors.positiveText.value,
  });

  useEffect(() => {
    if (
      [
        cabinetRoutes.mt4Terminal,
        cabinetRoutes.mt5Terminal,
        terminalRoutes.terminal,
        terminalRoutes.terminalMobile,
        terminalRoutes.terminalMobileChart,
        onboardingRoutes.onboarding,
      ].includes(location.pathname)
    ) {
      closeLiveChat();
    }
  }, [location.pathname]);

  return null;
};

export { IntercomWidget };
