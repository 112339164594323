import { type FC, type ReactElement } from "react";
import { Outlet } from "react-router-dom";

import { useSocketNotifications } from "@/entities/notifications/socket-notifications.hook";
import { useSocket } from "@/services/websocket";

type Props = {
  children?: ReactElement;
};

// TODO: think about naming
const SocketRoute: FC<Props> = ({ children }) => {
  useSocket();
  useSocketNotifications();

  return children ? children : <Outlet />;
};

export { SocketRoute };
