import { IconArchive } from "@/domains/icons";
import { useTranslation } from "@/hooks/translator.hook";
import { EmptyState } from "@/shared/ui";

const ArchivedAccountsEmptyState = () => {
  const { t } = useTranslation();
  return <EmptyState title={t("accounts.empty-state.archived-title")} icon={<IconArchive />} />;
};

export { ArchivedAccountsEmptyState };
