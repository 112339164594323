import { cva } from "class-variance-authority";
import type { ElementRef } from "react";
import { forwardRef } from "react";

import { IconClose } from "@/domains/icons";
import { Text, UnstyledButton } from "@/shared/ui";

import type { MergedTerminalSymbol } from "../../helpers/symbols";
import { SymbolIcon } from "../../symbols/icon";

const buttonStyles = cva(
  "group flex h-12 shrink-0 items-center gap-2 whitespace-nowrap rounded-md bg-card-bg px-3 transition-colors hover:bg-control-bg-active",
  {
    variants: {
      isActive: {
        true: "bg-control-bg-active",
      },
    },
  },
);

type Props = {
  symbolInfo: MergedTerminalSymbol;
  onSelect: () => void;
  onRemove?: () => void;
  isActive?: boolean;
};

const MobileChartSymbolButton = forwardRef<ElementRef<"div">, Props>(
  ({ symbolInfo, onRemove, isActive, onSelect }, forwardedRef) => {
    const { symbol } = symbolInfo;

    return (
      <div ref={forwardedRef} onClick={onSelect} className={buttonStyles({ isActive })} role="button">
        <SymbolIcon symbol={symbol!} />
        <Text color="primary" variant="M / Medium">
          {symbol}
        </Text>
        {onRemove && (
          <UnstyledButton
            className="text-contrast-secondary"
            onClick={e => {
              e.stopPropagation();
              onRemove();
            }}
          >
            <IconClose />
          </UnstyledButton>
        )}
      </div>
    );
  },
);

export { MobileChartSymbolButton };
