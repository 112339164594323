import { useTranslation } from "@/hooks/translator.hook";

import { TransactionsTable } from "../../ui/table";

const TransactionHeaderRow = () => {
  const { t } = useTranslation();
  return (
    <TransactionsTable.Row showBorder>
      <TransactionsTable.Head>{t("transactions.table.date")}</TransactionsTable.Head>
      <TransactionsTable.Head>{t("transactions.table.method")}</TransactionsTable.Head>
      <TransactionsTable.Head>{t("transactions.table.description")}</TransactionsTable.Head>
      <TransactionsTable.Head>{t("transactions.table.amount")}</TransactionsTable.Head>
      <TransactionsTable.Head>{t("transactions.table.status")}</TransactionsTable.Head>
    </TransactionsTable.Row>
  );
};

export { TransactionHeaderRow };
