import { useState } from "react";

import { useInfiniteScrollQuery } from "@/app/hooks";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { useTranslation } from "@/hooks/translator.hook";
import { type FundsTx, type FundsTxItemsContainer, FundsTxType } from "@/services/openapi";
import { Chip, ScrollArea, Spinner } from "@/shared/ui";
import { useInfiniteTransactionsQuery } from "@/state/server/funds";

import { TransactionsEmptyState } from "../../ui/empty-state";
import { TransactionsTable } from "../../ui/table";
import { TransactionCard } from "./transaction-card";
import { TransactionHeaderRow } from "./transaction-header-row";
import { TransactionTableRow } from "./transaction-table-row";

const TransactionsTableContainer = () => {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();

  const [type, setType] = useState<FundsTxType>(FundsTxType.All);

  const { fetchNextPage, hasNextPage, data, isFetchingNextPage, isLoading } = useInfiniteTransactionsQuery({
    pageSize: 20,
    type,
  });

  const { ref, items } = useInfiniteScrollQuery<FundsTx, FundsTxItemsContainer>({
    fetchNextPage,
    isFetchingNextPage,
    data,
  });

  return (
    <>
      <Chip.Group value={type} onValueChange={setType} className="mb-6">
        <Chip value={FundsTxType.All}>{t("transactions.filters.all")}</Chip>
        <Chip value={FundsTxType.Withdraw}>{t("transactions.filters.withdrawal")}</Chip>
        <Chip value={FundsTxType.Deposit}>{t("transactions.filters.deposit")}</Chip>
        <Chip value={FundsTxType.Converting}>{t("transactions.filters.transfer")}</Chip>
      </Chip.Group>

      <TransactionsTable items={items} emptyState={<TransactionsEmptyState />} isLoading={isLoading}>
        {isMobile ? (
          <div className="flex flex-col gap-3">
            {items.map(transaction => (
              <TransactionCard key={transaction.id} transaction={transaction} />
            ))}
          </div>
        ) : (
          <div>
            <ScrollArea scrollbars="horizontal">
              <table className="w-full">
                <thead>
                  <TransactionHeaderRow />
                </thead>
                <tbody>
                  {items.map(transaction => (
                    <TransactionTableRow key={transaction.id} transaction={transaction} />
                  ))}
                </tbody>
              </table>
            </ScrollArea>
          </div>
        )}
      </TransactionsTable>

      {hasNextPage && (
        <div ref={ref} className="my-8 flex justify-center *:size-6">
          <Spinner />
        </div>
      )}
    </>
  );
};

export { TransactionsTableContainer };
