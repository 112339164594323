import { cva } from "class-variance-authority";
import { type FC, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation } from "react-router-dom";

import { Logo } from "@/app/components";
import { useLocationPath } from "@/app/hooks";
import { amplitudeEvents, track } from "@/app/libs/amplitude";
import { toggleLiveChat } from "@/app/libs/intercom";
import { cabinetRoutes } from "@/routes/cabinet.routes";
import { getTerminalRoute, isCurrentPageTerminal } from "@/routes/terminal.routes";
import { Button, Sheet, UnstyledButton } from "@/shared/ui";
// import { useIsReadOnly } from "@/state/server/profile/profile.hooks"; TODO: not working in error page

const linkStyles = cva(
  "typography-S-Medium rounded-full px-4 py-3 text-start text-contrast-secondary outline-none transition-colors hover:text-contrast-primary focus-visible:text-contrast-primary focus-visible:outline-2 focus-visible:outline-accent-text active:text-contrast-primary",
  {
    variants: {
      active: {
        true: "!text-contrast-primary",
      },
    },
  },
);

type Props = {
  onClose: () => void;
};

const Sidebar: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();

  const location = useLocation();

  const currentLocation = useLocationPath();

  const isTerminalActive = useMemo(() => isCurrentPageTerminal(location.pathname), [location.pathname]);

  return (
    <div className="flex flex-col gap-3 px-3">
      <div className="mb-3 flex items-center justify-between gap-2 px-2 pt-8">
        <Logo />
        {/* TODO: theme switch */}
      </div>
      <div className="flex flex-wrap gap-3 px-1 *:flex-1">
        <Sheet.Close asChild>
          <Button size="sm" variant="secondary" asChild>
            <Link to={cabinetRoutes.withdrawal} data-test="navlink-withdrawal">
              {t("cabinet.navigation.withdrawal")}
            </Link>
          </Button>
        </Sheet.Close>
        <Sheet.Close asChild>
          <Button
            size="sm"
            onClick={isTerminalActive ? () => track(amplitudeEvents.deposit.fromTerminal) : void 0}
            asChild
          >
            <Link to={cabinetRoutes.deposit} data-test="navlink-deposit">
              {t("cabinet.navigation.deposit")}
            </Link>
          </Button>
        </Sheet.Close>
      </div>
      <div className="flex flex-col">
        <Link
          className={linkStyles({
            active: isTerminalActive,
          })}
          to={isTerminalActive ? currentLocation : getTerminalRoute("")}
          data-test="navlink-terminal"
        >
          {t("cabinet.navigation.terminal")}
        </Link>
        <NavLink
          className={({ isActive }) => linkStyles({ active: isActive })}
          to={cabinetRoutes.dashboard}
          data-test="navlink-accounts"
          onClick={onClose}
        >
          {t("cabinet.navigation.accounts")}
        </NavLink>
        <NavLink
          className={({ isActive }) => linkStyles({ active: isActive })}
          to={cabinetRoutes.profileSettings}
          data-test="navlink-profile-settings"
          onClick={onClose}
        >
          {t("cabinet.navigation.profile-settings")}
        </NavLink>
        <UnstyledButton data-test="navlink-support" onClick={toggleLiveChat} className={linkStyles()}>
          {t("cabinet.navigation.support")}
        </UnstyledButton>
      </div>
    </div>
  );
};

const Component = memo(Sidebar);

export { Component as Sidebar };
