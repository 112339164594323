import { type FC } from "react";
import { useTranslation } from "react-i18next";

import { NumberFormat } from "@/app/components";
import { getBonusMainPercent } from "@/entities/bonuses/helpers";
import { BonusTooltip } from "@/entities/bonuses/tooltip";
import type { BonusUserPlatform, TradingAccount } from "@/services/openapi";
import { DataList, Text } from "@/shared/ui";

type Props = {
  account: TradingAccount;
  bonusBalance: number;
  amount: number | null;
  bonus: BonusUserPlatform;
};

const TransferBonusBlock: FC<Props> = ({ account, bonusBalance, amount, bonus }) => {
  const { t } = useTranslation();

  const { currency, credit, digits } = account;

  return (
    <DataList>
      <DataList.Item
        label={
          <DataList.Label
            tooltip={
              <BonusTooltip
                bonus={bonus}
                actionText={t("transfer.bonus.button")}
                content={t("transfer.bonus.description", {
                  percent: getBonusMainPercent(bonus),
                })}
              />
            }
          >
            {t("transfer.bonus.text")}
          </DataList.Label>
        }
        value={
          <DataList.Value>
            {!amount ? (
              <NumberFormat value={credit} currency={currency!} decimalScale={digits} />
            ) : (
              <div className="flex items-center gap-1">
                <Text variant="inherit" color="tertiary" className="line-through">
                  <NumberFormat value={credit} currency={currency!} decimalScale={digits} />
                </Text>
                <NumberFormat value={bonusBalance} currency={currency!} decimalScale={digits} />
              </div>
            )}
          </DataList.Value>
        }
      />
    </DataList>
  );
};

export { TransferBonusBlock };
