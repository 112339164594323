import { hide, show } from "@intercom/messenger-js-sdk";
import { atom } from "nanostores";

const $liveChatState = atom<"hidden" | "open" | "minimized">("minimized");

const openLiveChat = () => {
  $liveChatState.set("open");
  show();

  document.documentElement.style.setProperty("--intercom-display", "block");
};

const closeLiveChat = () => {
  $liveChatState.set("hidden");
  hide();

  document.documentElement.style.setProperty("--intercom-display", "none");
};

const toggleLiveChat = () => {
  if ($liveChatState.get() === "open") {
    closeLiveChat();
  } else {
    openLiveChat();
  }
};

export { toggleLiveChat, openLiveChat, closeLiveChat };
