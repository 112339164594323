import { type ComponentProps, type ElementRef, forwardRef, memo } from "react";

import { NumberFormat } from "@/app/components";
import { AccountBadge } from "@/entities/accounts/badge";
import type { TradingAccount } from "@/services/openapi";
import { Select, Text } from "@/shared/ui";

const Item = forwardRef<ElementRef<"div">, { account: TradingAccount }>(({ account, ...props }, ref) => {
  const { type, login, balance, currency } = account;
  return (
    <div className="flex w-full items-center justify-between" ref={ref} {...props}>
      <div className="flex items-center gap-3">
        <AccountBadge type={type!} />
        <Text variant="S / Regular" color="secondary">
          #{login}
        </Text>
      </div>
      <Text variant="S / Regular" color="primary">
        <NumberFormat value={balance} currency={currency!} />
      </Text>
    </div>
  );
});

type Props = Pick<ComponentProps<typeof Select>, "disabled" | "pending" | "invalid" | "name"> & {
  accounts: TradingAccount[];
  accountId: string;
  setAccountId: (accountId: string) => void;
};

const AccountSelect = forwardRef<ElementRef<typeof Select>, Props>(
  ({ accounts, accountId, setAccountId, ...props }, ref) => (
    <Select value={accountId} onValueChange={setAccountId} ref={ref} {...props}>
      {accounts.map(account => {
        return (
          <Select.Item key={account.id} value={account.id!} asChild>
            <Item account={account} />
          </Select.Item>
        );
      })}
    </Select>
  ),
);

const Component = memo(AccountSelect);

export { Component as AccountSelect };
