import * as SelectPrimitive from "@radix-ui/react-select";
import { Slot } from "@radix-ui/react-slot";
import { type ComponentPropsWithoutRef, type ElementRef, type FC, forwardRef, type ReactNode } from "react";

import { IconChevronDown, IconTick } from "@/domains/icons";
import { cn } from "@/shared/styles";

import { type _ExternalWrapperProps, _inputTextStyles, _Wrapper } from "../inputs/_base";
import { popoverContentStyles } from "../popper";
import { ScrollArea } from "../scroll-area";

type TriggerProps = Omit<_ExternalWrapperProps, "endSection" | "hasInputValue" | "floatingLabel"> & {
  placeholder?: ReactNode;
  name?: string;
};
const Trigger = forwardRef<ElementRef<typeof SelectPrimitive.Trigger>, TriggerProps>(
  ({ placeholder, ...props }, ref) => (
    <SelectPrimitive.Trigger ref={ref} asChild>
      <_Wrapper
        type="select"
        endSection={
          <SelectPrimitive.Icon>
            <IconChevronDown />
          </SelectPrimitive.Icon>
        }
        {...props}
      >
        <div
          className={cn(
            _inputTextStyles(),
            "group/value group-data-[placeholder]/select-trigger:text-contrast-secondary",
          )}
          data-select-value
        >
          <SelectPrimitive.Value placeholder={placeholder} />
        </div>
      </_Wrapper>
    </SelectPrimitive.Trigger>
  ),
);

const Content: FC<{ children: ReactNode }> = ({ children }) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      className={cn(
        popoverContentStyles(),
        "max-h-[--radix-select-content-available-height] w-[var(--radix-select-trigger-width)] overflow-hidden rounded-lg bg-surface-elevation-2 shadow-modal",
      )}
      sideOffset={8}
      collisionPadding={20}
      position="popper"
    >
      <ScrollArea scrollbars="vertical" scrollOffset="sm">
        <SelectPrimitive.Viewport>{children}</SelectPrimitive.Viewport>
      </ScrollArea>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
);

const Item = forwardRef<
  ElementRef<typeof SelectPrimitive.Item>,
  Omit<ComponentPropsWithoutRef<typeof SelectPrimitive.Item>, "className"> & { asChild?: boolean }
>(({ children, asChild, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className="typography-S-Regular flex w-full cursor-pointer select-none items-center justify-between gap-3 p-4 text-contrast-primary outline-none transition-colors data-[highlighted]:bg-control-bg-hover data-[state=checked]:!bg-control-bg-active"
    {...props}
  >
    <SelectPrimitive.ItemText asChild={asChild}>{children}</SelectPrimitive.ItemText>
    <SelectPrimitive.ItemIndicator>
      <IconTick />
    </SelectPrimitive.ItemIndicator>
  </SelectPrimitive.Item>
));

const ItemHiddenValue: FC<{ children: ReactNode }> = ({ children }) => (
  <Slot className="group-data-[select-value=true]/value:hidden">{children}</Slot>
);

type Props = ComponentPropsWithoutRef<typeof Trigger> &
  Pick<ComponentPropsWithoutRef<typeof SelectPrimitive.Root>, "value" | "onValueChange" | "disabled"> & {
    children: ReactNode;
  };

const Select = forwardRef<ElementRef<typeof Trigger>, Props>(
  ({ children, value, onValueChange, name, ...props }, ref) => (
    <SelectPrimitive.Root disabled={props.disabled} name={name} onValueChange={onValueChange} value={value}>
      <Trigger ref={ref} {...props} />
      <Content>{children}</Content>
    </SelectPrimitive.Root>
  ),
);

const Component = Object.assign(Select, { Item, ItemHiddenValue });

export { Component as Select };
