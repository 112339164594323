import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { type ComponentPropsWithoutRef, type ElementRef, type FC, forwardRef, type ReactNode } from "react";

import { IconClose, IconTick } from "@/domains/icons";
import { cn } from "@/shared/styles";

import { centeredDialogContentStyles, IconButton, overlayStyles, ScrollArea, Text } from "..";
import { Dialog } from ".";

type SelectProps = Omit<
  ComponentPropsWithoutRef<typeof ToggleGroup.Root>,
  "defaultValue" | "value" | "onValueChange" | "type"
> & {
  value: string;
  onValueChange: (value: string) => void;
};

const Select = forwardRef<ElementRef<typeof ToggleGroup.Root>, SelectProps>(({ onValueChange, ...props }, ref) => {
  return (
    <ScrollArea scrollbars="vertical" scrollOffset="sm">
      <ToggleGroup.Root
        orientation="vertical"
        type="single"
        onValueChange={
          onValueChange
            ? value => {
                if (value) onValueChange(value);
              }
            : void 0
        }
        {...props}
        ref={ref}
      />
    </ScrollArea>
  );
});

const Group: FC<{ children?: ReactNode }> = ({ children }) => {
  return <div className="mt-2 first:mt-0">{children}</div>;
};

const Label: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <div className="sticky top-0 bg-surface-elevation-2 px-4 py-2">
      <Text variant="S / Regular" color="secondary">
        {children}
      </Text>
    </div>
  );
};

const Item = forwardRef<
  ElementRef<typeof ToggleGroup.Item>,
  Omit<ComponentPropsWithoutRef<typeof ToggleGroup.Item>, "className">
>((props, ref) => {
  return (
    <Dialog.Close asChild>
      <ToggleGroup.Item
        data-dialog-select-item
        className="group flex w-full items-center px-4 py-3.5 outline-none transition-colors hover:bg-control-bg-hover focus-visible:bg-control-bg-hover data-[state=on]:bg-control-bg-active"
        {...props}
        ref={ref}
      />
    </Dialog.Close>
  );
});

const ItemIndicator = () => {
  return (
    <div className="hidden *:size-6 group-data-[state=on]:block">
      <IconTick />
    </div>
  );
};

type ContentProps = Omit<ComponentPropsWithoutRef<typeof Dialog.PrimitiveContent>, "className"> & {
  title: ReactNode;
};

const Content = forwardRef<ElementRef<typeof Dialog.Content>, ContentProps>(({ children, title, ...props }, ref) => (
  <Dialog.Portal>
    <Dialog.Overlay className={overlayStyles()} />
    <Dialog.PrimitiveContent
      className={cn(
        centeredDialogContentStyles(),
        "flex max-h-[95dvh] w-[min(320px,_90vw)] flex-col overflow-auto rounded-xl bg-surface-elevation-2 pb-2 pt-5 shadow-modal outline-none",
      )}
      onOpenAutoFocus={event => {
        const activeElement = document.querySelector('[data-dialog-select-item][data-state="on"]:not([data-disabled])');
        // focus the active element if it exists
        if (activeElement && activeElement instanceof HTMLElement) {
          event.preventDefault();
          activeElement.focus();
          return;
        }

        const firstInactiveElement = document.querySelector(
          '[data-dialog-select-item][data-state="off"]:not([data-disabled])',
        );
        // focus the inactive element if it exists
        if (firstInactiveElement && firstInactiveElement instanceof HTMLElement) {
          event.preventDefault();
          firstInactiveElement.focus();
          return;
        }
      }}
      {...props}
      ref={ref}
    >
      <div className="mb-2 grid grid-cols-[1fr_auto_1fr] items-center px-2">
        <div></div>
        <Dialog.Title asChild>
          <Text as="h3" variant="M / Medium" color="primary" align="center">
            {title}
          </Text>
        </Dialog.Title>
        <div className="flex justify-end">
          <Dialog.Close asChild>
            <IconButton size="sm" variant="flat">
              <IconClose />
            </IconButton>
          </Dialog.Close>
        </div>
      </div>
      {children}
    </Dialog.PrimitiveContent>
  </Dialog.Portal>
));
Content.displayName = Dialog.PrimitiveContent.displayName;

const Component = Object.assign(Select, { Group, Content, Label, Item, ItemIndicator });

export { Component as DialogSelect };
