import { Slot } from "@radix-ui/react-slot";
import type { ComponentPropsWithoutRef, ElementRef, FC, ReactNode } from "react";
import { forwardRef } from "react";

import { IconClose } from "@/domains/icons";

import { Image, Text, UnstyledButton } from "..";

const ActionButton = forwardRef<
  ElementRef<typeof UnstyledButton>,
  ComponentPropsWithoutRef<typeof UnstyledButton> & { asChild?: boolean }
>(({ asChild, ...props }, ref) => {
  const Comp = asChild ? Slot : UnstyledButton;
  return <Comp className="typography-S-Medium text-positive-text" ref={ref} {...props} />;
});

const CloseButton = forwardRef<ElementRef<typeof UnstyledButton>, ComponentPropsWithoutRef<typeof UnstyledButton>>(
  (props, ref) => (
    <UnstyledButton
      className="absolute end-4 top-4 grid size-6 place-items-center rounded-full bg-static-white/20 text-static-white backdrop-blur-[2px] *:size-4"
      ref={ref}
      {...props}
    >
      <IconClose />
    </UnstyledButton>
  ),
);

type Props = {
  text: string;
  actionButton: ReactNode;
  closeButton?: ReactNode;
  bonusImg?: string;
};

const Banner: FC<Props> = ({ text, actionButton, closeButton, bonusImg }) => {
  return (
    <div className="relative flex overflow-hidden rounded-xl bg-surface-elevation-3">
      <div className="flex w-2/3 flex-col items-start gap-2 px-6 py-5">
        <Text color="staticWhite" variant="M / Medium">
          {text}
        </Text>
        {actionButton}
      </div>
      {/* TODO: image styles should come from component props */}
      {bonusImg && (
        <div className="relative grid grow place-items-center">
          <Image
            src={bonusImg}
            className="absolute bottom-[-51px] end-3 aspect-square h-[160px] w-[226px] -rotate-12 object-cover md:bottom-[-20px] md:end-auto md:top-[-25px] md:h-[270px] md:w-[380px]"
          />
        </div>
      )}
      {closeButton}
    </div>
  );
};

const Component = Object.assign(Banner, { ActionButton, CloseButton });

export { Component as Banner };
