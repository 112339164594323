import type { FC } from "react";
import { useMemo } from "react";

import { TradingServerPlatform } from "@/services/openapi";
import { useServersQuery } from "@/state/server/accounts";

const MT5TerminalPage: FC = () => {
  const { data: servers } = useServersQuery();

  const mt5Server = useMemo(
    () => servers?.find(({ platformType }) => platformType === TradingServerPlatform.MetaTrader5),
    [servers],
  );

  if (!servers) return null;

  return <iframe className="size-full" src={`${mt5Server!.terminalWebUrl}?mode=connect`} />;
};

export { MT5TerminalPage };
