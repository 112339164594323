import { cva } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { cn } from "@/shared/styles";

const containerStyles = cva("relative flex items-center justify-center gap-[6px]")();

const dotStyles = cva("h-2 w-2 animate-loader-dots rounded-full bg-current even:delay-500")();

/**
 * @deprecated
 */
const Loader = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(({ className, ...props }, ref) => (
  <div className={cn(containerStyles, className)} {...props} ref={ref}>
    <span className={dotStyles} />
    <span className={dotStyles} />
    <span className={dotStyles} />
  </div>
));

export { Loader };
