import { type ComponentPropsWithoutRef, type ElementRef, type FC, forwardRef, type ReactNode } from "react";

import { Button as UIButton, Text } from "@/shared/ui";

const Button = forwardRef<ElementRef<typeof UIButton>, ComponentPropsWithoutRef<typeof UIButton>>((props, ref) => {
  return <UIButton size="sm" {...props} ref={ref} />;
});
Button.displayName = "EmptyStateButton";

type Props = {
  title: ReactNode;
  description?: ReactNode;
  action?: ReactNode;
  icon?: ReactNode;
};

const EmptyState: FC<Props> = ({ title, action, description, icon }) => {
  return (
    <div className="flex min-h-[88px] flex-col items-center justify-center rounded-lg bg-card-bg px-10 py-8">
      {icon && (
        <div className="mb-3 grid size-14 place-items-center rounded-full bg-contrast-quinary text-contrast-secondary">
          {icon}
        </div>
      )}
      <Text align="center" variant="M / Medium" color="primary">
        {title}
      </Text>
      {description && (
        <Text className="mt-2" align="center" variant="S / Regular" color="secondary">
          {description}
        </Text>
      )}
      {action && <div className="mt-6">{action}</div>}
    </div>
  );
};

const Component = Object.assign(EmptyState, { Button });

export { Component as EmptyState };
