import dayjs from "dayjs";
import { useQueryClient } from "react-query";

import { getTransactions, preTransfer } from "@/services/funds";
import type { ApiRequestParamsType, ApiResponseType } from "@/services/types";

import { useBaseInfiniteQuery, useBaseQuery } from "..";
import type { InfiniteQueryOptionsType, QueryOptionsType, RequestInfiniteParams } from "../types";
import { fundsQueryKeys } from "./funds.keys";

export const useInfiniteTransactionsQuery = (
  filters: RequestInfiniteParams<ApiRequestParamsType<typeof getTransactions>>,
  options?: InfiniteQueryOptionsType<ApiResponseType<typeof getTransactions>>,
) => {
  const { pageSize, ...restFilters } = filters;
  return useBaseInfiniteQuery<ApiResponseType<typeof getTransactions>, ApiRequestParamsType<typeof getTransactions>>(
    fundsQueryKeys.transactionsInfinite(restFilters),
    getTransactions,
    filters,
    options,
  );
};

export const usePreTransferQuery = (
  filters: ApiRequestParamsType<typeof preTransfer>["fundsPreTransferRequest"],
  options?: QueryOptionsType<ApiResponseType<typeof preTransfer>>,
) => {
  const client = useQueryClient();
  return useBaseQuery<ApiResponseType<typeof preTransfer>>({
    queryKey: fundsQueryKeys.transferEvaluate(filters!),
    queryFn: () => preTransfer({ fundsPreTransferRequest: filters }),
    enabled: !!filters?.destinationCurrency && !!filters.sourceCurrency && !!filters.amount,
    onSuccess: res => {
      const newStaleTime = dayjs(res.validTo).diff(dayjs());
      client.setQueryDefaults(fundsQueryKeys.transferEvaluate(filters!), {
        staleTime: newStaleTime,
        refetchInterval: newStaleTime,
      });
    },
    ...options,
  });
};
