import { useStore } from "@nanostores/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { IconChevronRight } from "@/domains/icons";
import { getDepositRoute } from "@/routes/cabinet.routes";
import { $noticesMessage, socketClient } from "@/services/websocket";
import { Toast, toast, ToastIcons } from "@/shared/ui";

const useSocketNotifications = () => {
  const navigate = useNavigate();

  useEffect(() => {
    socketClient.subscribeUser();
    return () => {
      socketClient.unsubscribeUser();
    };
  }, []);

  const noticesMessage = useStore($noticesMessage);

  useEffect(() => {
    if (noticesMessage) {
      noticesMessage.n!.forEach(notice => {
        const { a: accountInfo, b: buttons, h: title, m: description, o: options, t: type } = notice;
        toast({
          title: title!,
          description: description ? description : void 0,
          icon: ToastIcons.ERROR, // TODO: add icons
          autoClose: !!options?.p,
          action:
            buttons && buttons[0] ? (
              <Toast.Action
                altText={buttons[0].t!}
                variant="positive"
                onClick={() => {
                  if (accountInfo && buttons[0]!.a === "make-deposit") {
                    navigate(getDepositRoute(accountInfo.i!));
                  }
                }}
                icon={<IconChevronRight />}
              >
                {buttons[0].t}
              </Toast.Action>
            ) : null,
        });
      });
    }
  }, [noticesMessage]);
};

export { useSocketNotifications };
