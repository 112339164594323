import type { FC } from "react";

import { NumberFormat } from "@/app/components";
import type { FundsTxAmounts } from "@/services/openapi";

type Props = {
  amounts: FundsTxAmounts;
};

const TransactionAmount: FC<Props> = ({ amounts }) => {
  const { first, second } = amounts;

  if (!first) {
    return null;
  }

  if (!second) {
    return <NumberFormat value={first.amount} currency={first.currency!} />;
  }

  return (
    <>
      <NumberFormat value={first.amount} currency={first.currency!} /> →{" "}
      <NumberFormat value={second.amount} currency={second.currency!} />
    </>
  );
};

export { TransactionAmount };
