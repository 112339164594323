import type * as Stitches from "@stitches/react";
import { createStitches } from "@stitches/react";
import { fontFamily, gilroyFont, robotoFont } from "tailwind.config";

import { breakpoints } from "./breakpoints";

export const { globalCss, styled, css, keyframes, config, theme, createTheme } = createStitches({
  theme: {
    colors: {
      primary: "#19C651",
      white: "#FFFFFF",
      black: "#212121",
      gray: "#E8E8E8",
      extraLightGray: "#BABABA",
      lightGray: "#F8F8F8",
      darkGray: "#7C7C7C",
      blue: "#0070FF",
      lite: "#FFFFFF",
      dark: "#212121",
      textMain: "#212121",
      textSecondary: "#7C7C7C",
      textInverse: "#FFFFFF",
      textPlaceholder: "#BABABA",
      bgPrimary: "#FFFFFF",
      bgDeep: "#E8E8E8",
      bgSelected: "#E8E8E8",
      bgBack: "#F8F8F8",
      bgAdditional: "#212121",
      bgBorder: "#E8E8E8",
      warningDark: "#E85500",
      warningDefault: "#FF7223",
      warningLight: "#FF9357",
      positiveDark: "#08BD46",
      positiveDefault: "#19C651",
      positiveLight: "#00B53E",
      negativeDark: "#E61717",
      negativeDefault: "#FF3434",
      negativeLight: "#FF6666",

      // new otp
      elevation1: "rgba(255, 255, 255, 1)",
      elevation2: "rgba(255, 255, 255, 1)",
      elevation3: "rgba(29, 30, 32, 1)",
      surfaceCanvas: "rgba(246, 246, 248, 1)",
      surfaceUnderCanvas: "rgba(232, 232, 232, 1)",
      surfaceOverlay: "rgba(0, 0, 0, 0.4)",

      contrastPrimary: "rgba(42, 42, 45, 1)",
      contrastSecondary: "rgba(87, 92, 117, 0.8)",
      contrastTertiary: "rgba(82, 88, 122, 0.5)",
      contrastQuaternary: "rgba(77, 85, 128, 0.14)",
      contrastQuinary: "rgba(77, 85, 128, 0.06))",

      controlBgDefault: "rgba(255, 255, 255, 0.9)",
      controlBgHover: "rgba(211, 213, 222, 0.16)",
      controlBgActive: "rgba(211, 213, 222, 0.34)",
      controlBgInactive: "rgba(255, 255, 255, 0.4)",
      controlBorder: "rgba(184, 186, 199, 0.35)",

      cardBgDefault: "rgba(255, 255, 255, 0.9)",
      cardBgAccent: "rgba(211, 213, 222, 0.25)",
      cardBgHover: "rgba(211, 213, 222, 0.3)",
      cardBgActive: "rgba(211, 213, 222, 0.5)",
      cardBgInactive: "rgba(255, 255, 255, 0.4)",
      cardBorder: "rgba(184, 186, 199, 0.00)",

      inputBgDefault: "rgba(211, 213, 222, 0.3)",
      inputBgHover: "rgba(211, 213, 222, 0.5)",
      inputBgActive: "rgba(211, 213, 222, 0.2)",
      inputBgInactive: "rgba(45, 47, 57, 0.04)",
      inputBorder: "rgba(184, 186, 199, 0)",

      accentText: "rgba(17, 21, 59, 0.6)",
      accentOver: "rgba(255, 255, 255, 1)",
      accentBgDefault: "rgba(29, 30, 32, 1)",
      accentBgHover: "rgba(84, 85, 87, 1)",
      accentBgActive: "rgba(29, 30, 32, 1)",

      positiveText: "rgba(25, 198, 81, 1)",
      positiveOver: "rgba(255, 255, 255, 1)",
      positiveBgDefault: "rgba(25, 198, 81, 1)",
      positiveBgHover: "rgba(74, 207, 116, 1)",
      positiveBgActive: "rgba(0, 153, 51, 1)",

      warningText: "rgba(255, 114, 35, 1)",
      warningOver: "rgba(255, 255, 255, 1)",
      warningBgDefault: "rgba(255, 114, 35, 1)",
      warningBgHover: "rgba(255, 147, 87, 1)",
      warningBgActive: "rgba(232, 85, 0, 1)",

      negativeText: "rgba(255, 52, 52, 1)",
      negativeOver: "rgba(255, 255, 255, 1)",
      negativeBgDefault: "rgba(255, 52, 52, 1)",
      negativeBgHover: "rgba(255, 102, 102, 1)",
      negativeBgActive: "rgba(229, 23, 23, 1)",

      constantLabel: "rgba(0, 112, 255, 1)",

      realLabel: "rgba(217, 234, 255, 1)",
      demoLabel: "rgba(255, 234, 222, 1)",
      constantLemon: "rgba(229, 241, 0, 1)",
      constantLightGreen: "rgba(160, 229, 87, 1)",

      staticBlack: "rgba(26, 26, 26, 1)",
      staticWhite: "rgba(255, 255, 255, 1)",
    },
    fonts: {
      gilroy: gilroyFont,
      roboto: robotoFont,
      aeonik: fontFamily,
    },
    fontWeights: {
      normal: "400",
      bold: "600",
      bolder: "700",
    },
    space: {
      containerXPadding: "16px",
    },
    sizes: {
      appContainer: "1300px",
    },
    radii: {
      round: "50%",
      common: "8px",
    },
    lineHeights: {
      1: "19px",
      2: "22px",
      3: "24px",
      4: "32px",
      5: "56px",
      6: "64px",
    },
  },
  media: {
    bp0: `(min-width: ${breakpoints.xs}px)`,
    bp1: `(min-width: ${breakpoints.sm}px)`,
    bp2: `(min-width: ${breakpoints.md}px)`,
    bp3: `(min-width: ${breakpoints.lg}px)`,
    bp4: `(min-width: ${breakpoints.xl}px)`,
  },
  utils: {
    p: (value: Stitches.PropertyValue<"padding">) => ({
      padding: value,
    }),
    pt: (value: Stitches.PropertyValue<"paddingTop">) => ({
      paddingTop: value,
    }),
    pr: (value: Stitches.PropertyValue<"paddingRight">) => ({
      paddingRight: value,
    }),
    pb: (value: Stitches.PropertyValue<"paddingBottom">) => ({
      paddingBottom: value,
    }),
    pl: (value: Stitches.PropertyValue<"paddingLeft">) => ({
      paddingLeft: value,
    }),
    px: (value: Stitches.PropertyValue<"paddingLeft">) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value: Stitches.PropertyValue<"paddingTop">) => ({
      paddingTop: value,
      paddingBottom: value,
    }),

    m: (value: Stitches.PropertyValue<"margin">) => ({
      margin: value,
    }),
    mt: (value: Stitches.PropertyValue<"marginTop">) => ({
      marginTop: value,
    }),
    mr: (value: Stitches.PropertyValue<"marginRight">) => ({
      marginRight: value,
    }),
    mb: (value: Stitches.PropertyValue<"marginBottom">) => ({
      marginBottom: value,
    }),
    ml: (value: Stitches.PropertyValue<"marginLeft">) => ({
      marginLeft: value,
    }),
    mx: (value: Stitches.PropertyValue<"marginLeft">) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: Stitches.PropertyValue<"marginTop">) => ({
      marginTop: value,
      marginBottom: value,
    }),
    elementTransition: (values: string) => ({
      transitionProperty: values,
      transitionDuration: "300ms",
      transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
      transitionDelay: "0s",
      willChange: values,
    }),
    hideScroll: () => ({
      "&::-webkit-scrollbar": {
        width: 0,
        height: 0,
      },
    }),
  },
  prefix: "doto",
});

export const darkTheme = createTheme("dark", {
  colors: {
    white: "#151515",
    black: "#EEEEEE",
    gray: "#034D11",
    extraLightGray: "#BABABA",
    lightGray: "#212121",
    darkGray: "#95AB99",
    lite: "#FFFFFF",
    dark: "#212121",
    textMain: "#EEEEEE",
    textSecondary: "#95AB99",
    textInverse: "#212121",
    bgPrimary: "#212121",
    bgDeep: "#181514",
    bgSelected: "#034D11",
    bgBack: "#151515",
    bgAdditional: "#EEEEEE",
    bgBorder: "hsla(133, 17%, 58%, 0.2)",
    warningDark: "#E85500",
    warningDefault: "#FF7223",
    warningLight: "#FF9357",
    positiveDark: "#08BD46",
    positiveDefault: "#19C651",
    positiveLight: "#00B53E",
    negativeDark: "#E61717",
    negativeDefault: "#FF3434",
    negativeLight: "#FF6666",

    /* otp new */
    elevation1: "rgba(29, 29, 29, 1)",
    elevation2: "rgba(31, 31, 31, 1)",
    elevation3: "rgba(41, 41, 41, 1)",
    surfaceCanvas: "rgba(24, 24, 24, 1)",
    surfaceUnderCanvas: "rgba(0, 0, 0, 1)",
    surfaceOverlay: "rgba(7, 8, 8, 0.56)",

    contrastPrimary: "rgba(242, 242, 242, 1)",
    contrastSecondary: "rgba(229, 249, 255, 0.56)",
    contrastTertiary: "rgba(229, 249, 255, 0.24)",
    contrastQuaternary: "rgba(229, 249, 255, 0.12)",
    /* ??? */
    contrastQuinary: "rgba(33, 33, 33, 0.06)",

    controlBgDefault: "rgba(242, 242, 242, 0.1)",
    controlBgHover: "rgba(241, 242, 243, 0.12)",
    controlBgactive: "rgba(242, 242, 242, 0.18)",
    controlBgInactive: "rgba(242, 242, 242, 0.04)",
    controlBorder: "rgba(242, 242, 242, 0)",

    cardBgDefault: "rgba(242, 242, 242, 0.08)",
    cardBgAccent: "rgba(242, 242, 242, 0.08)",
    cardBgHover: "rgba(242, 242, 242, 0.1)",
    cardBgActive: "rgba(242, 242, 242, 0.12)",
    cardBgInactive: "rgba(242, 242, 242, 0.03)",
    cardBorder: "rgba(242, 242, 242, 0)",

    inputBgDefault: "rgba(242, 242, 242, 0.06)",
    inputBgHover: "rgba(242, 242, 242, 0.07)",
    inputBgActive: "rgba(242, 242, 242, 0.02)",
    inputBgInactive: "rgba(242, 242, 242, 0.02)",
    inputBorder: "rgba(242, 242, 242, 0.1)",

    accentText: "rgba(25, 198, 81, 1)",
    accentOver: "rgba(41, 41, 41, 1)",
    accentBgDefault: "rgba(255, 255, 255, 1)",
    accentBgHover: "rgba(228, 228, 228, 1)",
    accentBgActive: "rgba(255, 255, 255, 1)",

    positiveText: "rgba(25, 198, 81, 1)",
    positiveOver: "rgba(255, 255, 255, 1)",
    positiveBgDefault: "rgba(40, 189, 102, 1)",
    positiveBgHover: "rgba(45, 214, 116, 1)",
    positiveBgActive: "rgba(16, 148, 71, 1)",

    warningText: "rgba(223, 147, 72, 1)",
    warningOver: "rgba(255, 255, 255, 1)",
    warningBgDefault: "rgba(223, 147, 72, 1)",
    warningBgHover: "rgba(227, 163, 99, 1)",
    warningBgActive: "rgba(199, 128, 57, 1)",

    negativeText: "rgba(255, 87, 101, 1)",
    negativeOver: "rgba(255, 255, 255, 1)",
    negativeBgDefault: "rgba(255, 87, 101, 1)",
    negativeBgHover: "rgba(255, 112, 124, 1)",
    negativeBgActive: "rgba(229, 67, 80, 1)",

    constantLabel: "rgba(0, 112, 255, 1)",
  },
});
