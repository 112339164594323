import { type FundsTxDetailStatus, FundsTxStatus } from "@/services/openapi";
import { Label } from "@/shared/ui";

const TransactionStatusBadge = ({ status: detailedStatus }: { status: FundsTxDetailStatus }) => {
  const { status, title } = detailedStatus;
  switch (status) {
    case FundsTxStatus.Rejected:
      return <Label variant="negative">{title}</Label>;
    case FundsTxStatus.Error:
      return <Label variant="negative">{title}</Label>;
    case FundsTxStatus.Refunded:
      return <Label variant="neutral">{title}</Label>;
    case FundsTxStatus.Finished:
      return <Label variant="positive">{title}</Label>;
    case FundsTxStatus.Processing:
      return <Label variant="info">{title}</Label>;
    default:
      return null;
  }
};

export { TransactionStatusBadge };
