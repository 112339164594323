import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { UnstyledButton } from "../..";

type Props = Omit<ComponentPropsWithoutRef<"button">, "className">;

const _Button = forwardRef<ElementRef<"button">, Props>((props, ref) => (
  <UnstyledButton
    className="grid select-none place-items-center text-contrast-primary transition-colors hover:text-contrast-secondary disabled:pointer-events-none disabled:text-contrast-tertiary group-data-[disabled=true]/input-wrapper:pointer-events-none group-data-[disabled=true]/input-wrapper:text-contrast-tertiary"
    ref={ref}
    {...props}
  />
));

export { _Button };
