import * as TabsPrimitive from "@radix-ui/react-tabs";
import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef } from "react";

import { cn } from "@/shared/styles";

const listStyles = cva(
  "group/tabs relative flex before:absolute before:inset-0 before:top-auto before:h-0.5 before:w-full before:bg-contrast-quaternary",
  {
    variants: {
      grow: {
        true: "*:flex-1",
      },
    },
  },
);

const List = forwardRef<
  ElementRef<typeof TabsPrimitive.List>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.List> & VariantProps<typeof listStyles>
>(({ className, grow = false, ...props }, ref) => (
  <TabsPrimitive.List ref={ref} data-grow={grow} className={cn(listStyles({ grow }), className)} {...props} />
));

const Content = forwardRef<
  ElementRef<typeof TabsPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content ref={ref} className={cn("outline-none", className)} {...props} />
));

const Trigger = forwardRef<
  ElementRef<typeof TabsPrimitive.Trigger>,
  Omit<ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>, "className">
>(({ children, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className="group/trigger typography-M-Medium relative inline-flex h-12 items-center justify-center whitespace-nowrap rounded-md text-contrast-secondary outline-none transition-colors hover:text-contrast-primary focus-visible:outline-2 focus-visible:-outline-offset-2 focus-visible:outline-accent-text disabled:pointer-events-none disabled:text-contrast-tertiary data-[state=active]:text-contrast-primary group-data-[grow=false]/tabs:px-4"
    {...props}
  >
    <div className="relative grid h-full place-items-center before:absolute before:inset-0 before:top-auto before:h-0.5 before:w-full before:rounded-full before:transition-colors group-data-[grow=true]/tabs:static group-data-[state=active]/trigger:before:bg-contrast-primary">
      {children}
    </div>
  </TabsPrimitive.Trigger>
));

type RootProps<T extends string> = Omit<
  ComponentPropsWithoutRef<typeof TabsPrimitive.Root>,
  "defaultValue" | "value" | "onValueChange"
> & {
  defaultValue?: T;
  value?: T;
  onValueChange?: (value: T) => void;
};

// @ts-ignore
const Root = <T extends string>(props: RootProps<T>) => <TabsPrimitive.Root activationMode="manual" {...props} />;

const Component = Object.assign(Root, {
  List,
  Trigger,
  Content,
});

export { Component as Tabs };
