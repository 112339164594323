import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, type ElementRef, forwardRef, type ReactNode } from "react";

import { cn } from "@/shared/styles";
import { Spinner, Text } from "@/shared/ui";

const cellStyles = cva("table-cell whitespace-nowrap px-4")();

const Head = forwardRef<ElementRef<typeof Text>, ComponentPropsWithoutRef<typeof Text>>(
  ({ align = "start", color = "secondary", ...props }, ref) => (
    <Text
      as="th"
      className={cn(cellStyles, "h-12")}
      align={align}
      color={color}
      variant="XS / Regular"
      {...props}
      ref={ref}
    />
  ),
);

const Cell = forwardRef<ElementRef<typeof Text>, ComponentPropsWithoutRef<typeof Text>>(
  ({ align = "start", color = "primary", className, ...props }, ref) => (
    <Text
      as="td"
      className={cn(cellStyles, "h-14", className)}
      align={align}
      color={color}
      variant="S / Regular"
      {...props}
      ref={ref}
    />
  ),
);

const rowStyles = cva("border-b border-contrast-quinary last:border-0", {
  variants: {
    showBorder: {
      true: "!border-b",
    },
    hoverable: {
      true: "cursor-pointer transition-colors hover:bg-control-bg-hover",
    },
  },
});

const Row = forwardRef<
  ElementRef<"tr">,
  Omit<ComponentPropsWithoutRef<"tr">, "className"> & VariantProps<typeof rowStyles>
>(({ showBorder, hoverable, ...props }, ref) => (
  <tr className={rowStyles({ showBorder, hoverable })} {...props} ref={ref} />
));

type Props<T> = {
  emptyState: ReactNode;
  isLoading: boolean;
  items: T[];
  children: ReactNode;
};

const Root = <T,>({ items, emptyState, isLoading, children }: Props<T>) => {
  if (isLoading) {
    return (
      <div className="my-8 flex justify-center *:size-6">
        <Spinner />
      </div>
    );
  }

  if (items.length === 0) {
    return emptyState as JSX.Element;
  }

  return children as JSX.Element;
};

const Component = Object.assign(Root, { Row, Head, Cell });

export { Component as TransactionsTable };
