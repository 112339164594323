import type { FC } from "react";

import { useTranslation } from "@/hooks/translator.hook";
import type { FundsTx } from "@/services/openapi";
import { DataList, Dialog, Text } from "@/shared/ui";

import { TransactionStatusBadge } from "./transaction-status-badge";
import { TransactionAmount } from "./transactions-table/transaction-amount";

type Props = {
  transaction: FundsTx;
};

const TransactionDialogContent: FC<Props> = ({ transaction }) => {
  const { status, details, description, amounts } = transaction;

  const { t } = useTranslation();

  return (
    <>
      <Dialog.Header title={t("transactions.dialog.title")} centered />
      <Text variant="S / Regular" align="center" color="secondary">
        {description}
      </Text>
      <div className="mb-4 mt-3 flex justify-center">
        <TransactionStatusBadge status={status!} />
      </div>
      <DataList>
        <DataList.Item
          label={<DataList.Label>{t("transactions.table.amount")}</DataList.Label>}
          value={
            <DataList.Value>
              <TransactionAmount amounts={amounts!} />
            </DataList.Value>
          }
        />
        {details &&
          details.map(({ description, title, amount }, i) => (
            <DataList.Item
              key={i}
              label={<DataList.Label>{title}</DataList.Label>}
              value={
                <DataList.Value>
                  {amount ? <TransactionAmount amounts={{ first: amount }} /> : description}
                </DataList.Value>
              }
            />
          ))}
      </DataList>
    </>
  );
};

export { TransactionDialogContent };
