import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";

import { cn } from "@/shared/styles";
import { UnstyledButton } from "@/shared/ui";

type Props = Omit<ComponentPropsWithoutRef<typeof UnstyledButton>, "className"> & {
  size?: "sm" | "md";
};

/**
 * @deprecated
 */
const TooltipQuestionIcon = forwardRef<ElementRef<typeof UnstyledButton>, Props>(({ size = "md", ...props }, ref) => (
  <UnstyledButton
    className={cn(
      "cursor-default text-contrast-tertiary outline-none transition-colors data-[state=delayed-open]:text-contrast-primary data-[state=instant-open]:text-contrast-primary",
      size === "sm" && "*:size-4",
    )}
    ref={ref}
    {...props}
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM8.00004 8.5C7.90318 9.27289 8.22826 9.90634 9.00008 10C10.2016 10.1458 10.3366 9.62752 10.4939 9.02324L10.5 9C10.6309 8.49786 11.0201 7.9 12 7.9C12.9799 7.9 13.8 8.50802 13.8 9.5C13.8 10.5088 13.1487 10.9958 12.4743 11.5C11.7504 12.0413 11 12.6023 11 13.85C11 14.55 11.3 15 12.1 15C12.7 15 13 14.6 13 14.1C13 13.4531 13.5952 12.9938 14.2722 12.4713C15.165 11.7823 16.2 10.9835 16.2 9.5C16.2 7 14.0861 6 11.9605 6C9.83495 6 8.16917 7.1503 8.00004 8.5ZM13.0999 17C13.0999 17.6075 12.6074 18.1 11.9999 18.1C11.3924 18.1 10.8999 17.6075 10.8999 17C10.8999 16.3925 11.3924 15.9 11.9999 15.9C12.6074 15.9 13.0999 16.3925 13.0999 17Z"
        fill="currentColor"
      />
    </svg>
  </UnstyledButton>
));

TooltipQuestionIcon.displayName = "TooltipQuestionIcon";

export { TooltipQuestionIcon };
