import dayjs from "dayjs";
import { type FC } from "react";

import { IconArrowTransfer } from "@/domains/icons";
import { useDisclosure } from "@/hooks/disclosure.hook";
import type { FundsTx } from "@/services/openapi";
import { Dialog, Text } from "@/shared/ui";

import { TransactionsTable } from "../../ui/table";
import { TransactionDialogContent } from "../transaction-dialog-content";
import { TransactionStatusBadge } from "../transaction-status-badge";
import { TransactionAmount } from "./transaction-amount";

export const getTransactionTypeIcon = (type: string) => {
  switch (type) {
    case "Transfer":
      return <IconArrowTransfer className="size-6 text-contrast-secondary" />;
    case "BankCard":
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.0461 2H2.04614C1.49386 2 1.04614 2.44772 1.04614 3V13C1.04614 13.5523 1.49386 14 2.04614 14H17.0461C17.5984 14 18.0461 13.5523 18.0461 13V3C18.0461 2.44772 17.5984 2 17.0461 2Z"
            fill="#0076CC"
          />
          <path d="M1.04614 5H18.0461V7H1.04614V5Z" fill="#2A405B" />
          <path
            d="M21.9539 10H6.95386C6.40157 10 5.95386 10.4477 5.95386 11V21C5.95386 21.5523 6.40157 22 6.95386 22H21.9539C22.5061 22 22.9539 21.5523 22.9539 21V11C22.9539 10.4477 22.5061 10 21.9539 10Z"
            fill="#33A9FF"
          />
          <path d="M6.93172 17H21.8859V18H6.93172V17ZM6.93172 19H11.986V20H6.93172V19Z" fill="#0076CC" />
        </svg>
      );
    default:
      return null;
  }
};

type Props = {
  transaction: FundsTx;
};

const TransactionTableRow: FC<Props> = ({ transaction }) => {
  const { date, description, amounts, type, status, typeTitle } = transaction;

  const [opened, { onOpenChange, open }] = useDisclosure();

  return (
    <Dialog open={opened} onOpenChange={onOpenChange}>
      <TransactionsTable.Row hoverable onClick={open}>
        <TransactionsTable.Cell>{dayjs(date).format("DD.MM.YYYY")}</TransactionsTable.Cell>
        <TransactionsTable.Cell>
          <div className="flex items-center gap-2">
            {getTransactionTypeIcon(type!)}
            {typeTitle}
          </div>
        </TransactionsTable.Cell>
        <TransactionsTable.Cell className="whitespace-pre-wrap">{description}</TransactionsTable.Cell>
        <TransactionsTable.Cell>
          <Text variant="S / Medium" color="inherit">
            <TransactionAmount amounts={amounts!} />
          </Text>
        </TransactionsTable.Cell>
        <TransactionsTable.Cell>
          <TransactionStatusBadge status={status!} />
        </TransactionsTable.Cell>
      </TransactionsTable.Row>

      <Dialog.Content>
        <TransactionDialogContent transaction={transaction} />
      </Dialog.Content>
    </Dialog>
  );
};

export { TransactionTableRow };
