import { css } from "@/styles";

export const headerStyles = css({
  py: "22px",
  px: "$containerXPadding",
  maxWidth: "$appContainer",
  margin: "0 auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export const headerWrapperStyles = css({
  background: "$white",
});
