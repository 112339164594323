import dayjs from "dayjs";
import type { FC } from "react";

import { TransactionStatusBadge } from "@/features/transactions/components/transaction-status-badge";
import { getTransactionTypeIcon } from "@/features/transactions/components/transactions-table/transaction-table-row";
import type { FundsTx } from "@/services/openapi";
import { Dialog, Text, UnstyledButton } from "@/shared/ui";

import { TransactionDialogContent } from "../transaction-dialog-content";
import { TransactionAmount } from "./transaction-amount";

type Props = {
  transaction: FundsTx;
};

const TransactionCard: FC<Props> = ({ transaction }) => {
  const { date, status, amounts, type, typeTitle } = transaction;

  return (
    <Dialog>
      <Dialog.Trigger asChild>
        <UnstyledButton className="flex flex-col items-stretch gap-2 rounded-lg border border-card-border bg-card-bg p-4">
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-2">
              {getTransactionTypeIcon(type!)}
              <Text color="primary" variant="S / Regular">
                {typeTitle}
              </Text>
            </div>
            <Text color="primary" variant="S / Regular">
              {dayjs(date).format("DD.MM.YYYY")}
            </Text>
          </div>
          <div className="flex items-center justify-between gap-2">
            <Text variant="S / Medium" color="inherit">
              <TransactionAmount amounts={amounts!} />
            </Text>
            <TransactionStatusBadge status={status!} />
          </div>
        </UnstyledButton>
      </Dialog.Trigger>
      <Dialog.Content>
        <TransactionDialogContent transaction={transaction} />
      </Dialog.Content>
    </Dialog>
  );
};

export { TransactionCard };
