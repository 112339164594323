import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { openLiveChat } from "@/app/libs/intercom";
import { IconGlassCrossSmall } from "@/domains/ui-kit";
import { Button, Dialog, Text } from "@/shared/ui";

type Props = {
  errorMessage: string | null;
};

const WithdrawalErrorDialogContent: FC<Props> = ({ errorMessage }) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog.Header
        title={t("withdrawal.status-dialog.error-title")}
        centered
        image={
          <Dialog.Image size="lg">
            <IconGlassCrossSmall />
          </Dialog.Image>
        }
      />
      <Text variant="S / Regular" color="primary" align="center">
        {errorMessage || t("withdrawal.status-dialog.error-description")}
      </Text>
      <Dialog.Buttons>
        <Dialog.Close asChild>
          <Button size="md">{t("button.try-again")}</Button>
        </Dialog.Close>
        <Dialog.Close asChild>
          <Button variant="secondary" onClick={openLiveChat}>
            {t("button.contact-support")}
          </Button>
        </Dialog.Close>
      </Dialog.Buttons>
    </>
  );
};

export { WithdrawalErrorDialogContent };
